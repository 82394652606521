import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import BaseTemplate from '../../templates/BaseTemplate';

// Tell Webpack this JS file uses this image
import imgStat1 from '../../assets/images/icon-money-bag.png';
import imgStat2 from '../../assets/images/icon-checklist.png';
import imgStat3 from '../../assets/images/icon-risk-manage.png';
import imgResource1 from '../../assets/images/resource-1.png';
import imgResource2 from '../../assets/images/resource-2.png';
import imgResource3 from '../../assets/images/partner-logo-fortinet.png';
import TrailCards from '../../components/TrailCards'

const stats = [
  {
    title: 'The cost',
    description: '<span class="Fw(b)">$13 million</span> was the estimated average yearly cost of cybercrime for an organization in 2018.',
    reference: '1',
    image: imgStat1
  },
  {
    title: 'Learn to Prepare',
    description: '<span class="Fw(b)">26%</span> of employees know what to do in the event of a breach.',
    reference: '2',
    image: imgStat2
  },
  {
    title: 'Manage Your Risk',
    description: '<span class="Fw(b)">$124 billion</span> is forecasted worldwide spend on cybersecurity this year.',
    reference: '3',
    image: imgStat3
  }
];

const resources = [
  {
    description: 'Article: Helping small businesses fight cybercrime benefits the global ecosystem.',
    link: 'https://www.weforum.org/agenda/2019/05/helping-small-businesses-fight-cybercrime-benefits-the-global-ecosystem/',
    image: imgResource1,
    imageClass: 'resource-image-full'
  },
  {
    description: 'An online resource that includes a wealth of free and effective tools.',
    link: 'https://www.weforum.org/centre-for-cybersecurity/initiatives',
    image: imgResource2,
    imageClass: 'resource-image-full'
  },
  {
    description: 'Educational materials including critical threat reports and top research analyst reports.',
    link: 'https://www.fortinet.com/resources.html',
    image: imgResource3,
    imageClass: ''
  }
];

const statSection = [];
const resourceSection = [];

stats.forEach((item, index) => {
  const refSection = (
    <span className="custom-uppertext">{item.reference}</span>
  );
  const i = index + 1;
  const itemHtml = (
    <div key={`stat-${i}`} className="">
      <div className="slds-card tds-card wef-card-stat custom-m-vertical--small slds-text-align--center">
        <div>
          <div className="">
            <img src={item.image} />
          </div>
          <div className="Fz(24) Lh(62) Fw(b) Ff(NexaRustBlack)">{item.title}</div>
          <div className="Fz(18) Lh(27) slds-m-top--medium">
            <span
              key={`body`}
              id="___gatsby"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
            <span className="custom-uppertext">{item.reference}</span>
          </div>
        </div>
      </div>
    </div>
  );

  statSection.push(itemHtml);
});

resources.forEach((item, index) => {
  const i = index + 1;
  const resource = (
    <div key={`resounrce-${i}`} className="slds-size--1-of-1 slds-large-size--1-of-3 slds-p-horizontal--medium H(100%)">
      <div className="slds-align_absolute-center ">
        <div className="slds-card tds-card wef-card-resource">
          <img alt={`Resource ${i}`} className={item.imageClass} src={item.image} />
        </div>
      </div>
      <div className="slds-align_absolute-center" style={{height: 'calc(100% - 138px - 39px - 1.5rem)'}}>
        <div className="slds-m-top--large C(gray1) Maw(358)">
          {item.description}
        </div>
      </div>
      <div className="slds-text-align--center custom-small-m-top--large slds-m-top--x-large">
        <a
          className="slds-button slds-button_neutral tds-button_neutral"
          href={item.link}
          target="_blank"
        >
          Read More
        </a>
      </div>
    </div>
  );

  resourceSection.push(resource);
});


const trailCardsQuery = graphql`
  query trailCardsOrganizations {
    allTrailCardsDataJson(filter: {appearsIn: {glob: "organizations"}}) {
      edges {
        node {
          duration
          description
          color
          image
          imageHasBackground
          points
          title
          type
          url
        }
      }
    }
  }
`;

const renderTrailCards = ({allTrailCardsDataJson}) => {
  return <TrailCards trailCards={allTrailCardsDataJson.edges.map(({node}) => node)}/>;
};

const OrganizationsPage = () => (
  <BaseTemplate page="organizations">
    <div className="wef-hero-organizations custom-align--vertical-center">
      <div className="slds-container--x-large slds-container--center slds-m-top--medium">
        <div className="slds-grid">
          <div className="slds-size--12-of-12">
            <div className="wef-hero_title">
              Secure your organization and team
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="wef-section Bgc(blue-dark)">
      <div className="wef-section_title slds-container--center C(white) Maw(720)">
        The value of cybersecurity awareness is bigger than ever
      </div>
      <div className="slds-container--x-large slds-container--center slds-m-top--medium">
        <div className="slds-grid slds-wrap slds-clearfix slds-grid--vertical-stretch slds-grid--align-center slds-grid_pull-padded-medium">
          {statSection}
        </div>
      </div>
    </div>

    <div className="wef-section Bgc(gray)">
      <div className="wef-section_title slds-container--center">
        Start your learning journey
      </div>
      <div className="slds-text-align--center Maw(440) Fz(18) slds-container--center">
        Learn new cybersecurity skills to protect your organization with Trailhead
      </div>
      <div className="custom-container--x-large slds-container--center slds-m-top--medium">
        <StaticQuery query={trailCardsQuery} render={renderTrailCards}/>
      </div>
    </div>

    {
      // <div className="wef-section">
      //   <div className="custom-container--x-large slds-container--center">
      //     <div className="wef-section_title slds-container--center">
      //       Resources
      //     </div>
      //     <div className="slds-grid slds-grid--align-center slds-wrap slds-grid--vertical-stretch slds-grid_pull-padded-medium slds-p-top--x-small resources-grid">
      //       {resourceSection}
      //     </div>
      //   </div>
      // </div>
    }
  </BaseTemplate>
);
export default OrganizationsPage;
